import { basePath, apiVersion } from './config';

export async function getPdfApi(token, id, pdfName, tenant) {
  const url = `${basePath}/${apiVersion}/download-portal/${id}&${pdfName}?tenant=${tenant}`;

  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/pdf',
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response;
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
