import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  List,
  Button,
  Tag,
  notification,
  Tooltip,
  Divider,
  Input,
  Form,
  Row,
  Col,
  Select,
  message,
  Anchor,
  Radio,
  Affix,
} from 'antd';
import dayjs from 'dayjs';
import './ListTurns.scss';
import { useSelector } from 'react-redux';
import { getAccessTokenApi } from '../../../api/auth';
import {
  addTurnApi,
  addTurnPortalApi,
  deleteTurnApi,
  updateTurnApi,
  updateTurnPortalApi,
} from '../../../api/turn';
import Modal from '../../Modal/Modal';
import { getSocialWorksApi } from '../../../api/patient';
import Spin from '../../Spin';
import { countryCodes } from '../../../utils/constants';
import { getServicesApi } from '../../../api/service';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CustomLoading from '../../CustomLoading/CustomLoading';
import NewTurnForm from '../NewTurnForm/NewTurnForm';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ListTurns(props) {
  const {
    slots,
    turnUser,
    turns,
    setReload,
    turnId,
    size,
    turnType,
    date,
    loadingParent,
    doctorData,
    setTurnDetail,
  } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const { _id, socialWork, email } = useSelector(
    (store) => store.userData.user
  );
  const { tenantId, tenantTimezone, countryCode } = useSelector(
    (store) => store.tenantData
  );
  const [patientForm] = Form.useForm();

  async function addTurn(turn) {
    const token = await getAccessTokenApi();

    if (!_id) {
      setIsVisibleModal(true);
      setModalTitle(
        <p style={{ textTransform: 'capitalize' }}>
          {dayjs(date).format('dddd')} {dayjs(date).format('D')} de
          {dayjs(date).format('MMMM')} {dayjs(turn?.hourStart).format('HH mm')}{' '}
          hs
        </p>
      );
      setModalContent(
        <NewTurnForm
          date={date}
          scheduleConfig={doctorData?.scheduleConfig}
          turnId={turnId}
          turnUser={turnUser}
          turnType={turnType}
          hourStart={turn?.hourStart}
          turns={turns}
          doctor={doctorData}
          setIsVisibleModal={setIsVisibleModal}
          size={turns?.length > 0 ? turns.sizeSlot : size}
          setTurnDetail={setTurnDetail}
        />
      );
    } else {
      message.loading('Solicitando turno, aguarde un momento...', 0);

      turnId
        ? await updateTurnApi({
            token,
            turnId,
            dataTurn: {
              hourStart: turn?.hourStart,
              patient: _id,
              socialWork: socialWork,
              email: email,
              portal: true,
              studies: turnType !== 'medico' ? [{ name: turnType }] : [],
            },
          })
            .then((response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
              } else {
                notification['success']({ message: response.message });
                setReload(true);
              }
            })
            .catch((err) =>
              notification['error']({ message: 'Error del servidor' })
            )
        : await addTurnApi({
            token,
            data: {
              date: date,
              user: turnUser,
              sizeSlot: size,
              dataTurn: {
                hourStart: turn?.hourStart,
                patient: _id,
                socialWork: socialWork,
                email: email,
                portal: true,
                studies: turnType !== 'medico' ? [{ name: turnType }] : [],
                turnType: turnType,
              },
            },
          })
            .then((response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
              } else {
                notification['success']({ message: response.message });
                setReload(true);
              }
            })
            .catch((err) =>
              notification['error']({ message: 'Error del servidor' })
            );
      message.destroy();
    }
  }

  return (
    <div className="turns-list">
      <Anchor offsetTop={5}>
        <h3 className="title-date">
          {dayjs(date).format('dddd')} {dayjs(date).format('D')} de{' '}
          {dayjs(date).format('MMMM')}
        </h3>
      </Anchor>
      <p
        style={{
          textAlign: 'center',
          fontSize: '12pt',
          color: 'grey',
        }}
      >
        {slots?.length > 0 ? 'Seleccione un horario' : 'Agenda completa'}
      </p>
      <List
        grid={{ gutter: 16, xs: 4 }}
        dataSource={slots}
        size="small"
        renderItem={(slot, index) => (
          <List.Item>
            <div className="turns-list__date">
              <Button onClick={() => addTurn(slot)}>
                <span>
                  {dayjs(slot?.hourStart).tz(tenantTimezone).format('HH:mm')}
                </span>
              </Button>
            </div>
          </List.Item>
        )}
        locale={{ emptyText: 'No hay mas turnos, intente otro día.' }}
        loading={
          loadingParent && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={modalTitle === 'Cancelar turno' ? '50%' : '90%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
