import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useSelector } from 'react-redux';
import './LayoutAdmin.scss';
import Turns from '../pages/Turns';
import Error404 from '../pages/Error404';
import Spin from '../components/Spin';

export default function LayoutAdmin(props) {
  const { routes } = props;

  const { user, isLoading } = useAuth();

  const { organization, onlineTurns, tenantId, versionPortal } = useSelector(
    (store) => store.tenantData
  );

  window.document.title = `Turnos | ${organization || ''}`;

  // Si se está cargando, mostrar el componente Spin

  if (isLoading) return <Spin />;

  // Si no hay turnos en línea, mostrar Error404
  if (!onlineTurns) {
    return <Error404 />;
  }

  if (onlineTurns && versionPortal === 'premium') {
    return <LoadRoutes routes={routes} />;
  }

  return tenantId ? (
    <Switch>
      <Route path={`/${tenantId}`} component={Turns} />
      <Redirect from="/" to={`/${tenantId}`} />
    </Switch>
  ) : (
    <Redirect to={'/'} />
  );
}

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}
