import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const generateSlots = async ({
  sizeSlot,
  turns,
  userTurn,
  date,
  tenantTimezone,
}) => {
  let generate = [];
  let minutesOfHour = 60;

  let configSchedule = {
    excludeDays: [],
    lu: {},
    ma: {},
    mi: {},
    ju: {},
    vi: {},
    sá: {},
    do: {},
  };

  if (
    dayjs(date).tz(tenantTimezone).diff(dayjs(), 'days') >
    (userTurn?.scheduleConfig?.quantityDaysPortal || 15)
  ) {
    return false;
    //setDate(dayjs().tz(tenantTimezone).format('YYYY-MM-DDTHH:mm:ss'));
  }

  let usualConfigSchedule = false;

  const updateConfigSchedule = (scheduleConfig, variable) => {
    scheduleConfig?.hours?.forEach((h, index) => {
      const start = dayjs(scheduleConfig.hours[index]?.start);
      const end = dayjs(scheduleConfig.hours[index]?.end);

      h?.days?.forEach((e) => {
        const entriesLength = Object.entries(variable[e]).length;
        variable[e] =
          entriesLength === 0
            ? { first: [start, end] }
            : entriesLength === 1
            ? { ...variable[e], second: [start, end] }
            : { ...variable[e] };
      });
    });
  };

  if (turns?.scheduleConfig && turns?.shifts?.length > 0) {
    await updateConfigSchedule(turns.scheduleConfig, configSchedule);
    usualConfigSchedule = {
      excludeDays: [],
      lu: {},
      ma: {},
      mi: {},
      ju: {},
      vi: {},
      sá: {},
      do: {},
    };
    await updateConfigSchedule(turns.scheduleConfig, usualConfigSchedule);
  } else {
    await updateConfigSchedule(userTurn.scheduleConfig, configSchedule);
  }

  let hours =
    configSchedule[`${dayjs(date).format('dd')}`]?.first !== undefined &&
    configSchedule[`${dayjs(date).format('dd')}`]?.first[0].format('H');

  let minutes =
    configSchedule[`${dayjs(date).format('dd')}`]?.first !== undefined &&
    configSchedule[`${dayjs(date).format('dd')}`]?.first[0].format('mm');

  let hourStart = hours && dayjs(date).startOf('day').add(hours, 'h');

  hourStart = hourStart && hourStart.add(minutes, 'm');

  let res1 =
    hourStart &&
    configSchedule[`${hourStart.format('dd')}`]?.first !== undefined
      ? configSchedule[`${hourStart.format('dd')}`]?.first[1].diff(
          configSchedule[`${hourStart.format('dd')}`]?.first[0],
          'minute'
        ) / minutesOfHour
      : 0;

  let q1 = (minutesOfHour / sizeSlot) * res1;

  let res2 =
    hourStart &&
    configSchedule[`${hourStart.format('dd')}`]?.second !== undefined
      ? configSchedule[`${hourStart.format('dd')}`]?.second[1].diff(
          configSchedule[`${hourStart.format('dd')}`]?.second[0],
          'minute'
        ) / minutesOfHour
      : 0;

  let q2 = (minutesOfHour / sizeSlot) * res2 + (res2 !== 0 ? 1 : 0);

  const isDateExcluded = userTurn?.scheduleConfig?.excludeDays?.some((e) =>
    dayjs(e).tz(tenantTimezone).isSame(dayjs(date).tz(tenantTimezone), 'day')
  );

  if (!isDateExcluded) {
    for (let i = 0; i < q1 + q2; i++) {
      generate[i] = {
        hourStart: hourStart.toISOString(),
      };
      if (i == Math.round(q1)) {
        if (configSchedule[`${hourStart.format('dd')}`]?.second !== undefined) {
          let hours =
            configSchedule[`${dayjs(date).format('dd')}`]?.second[0].format(
              'H'
            );

          let minutes =
            configSchedule[`${dayjs(date).format('dd')}`]?.second[0].format(
              'mm'
            );

          hourStart = dayjs(date).startOf('day').add(hours, 'h');

          hourStart = hourStart.add(minutes, 'm');

          generate[i] = {
            hourStart: hourStart.toISOString(),
            exclude: true,
            second: true,
          };
          continue;
        }
      }

      hourStart = hourStart.add(sizeSlot, 'm');
    }
  } else {
    generate[0] = {
      exclude: true,
    };
  }
  const updatedSlots = generate?.map((slot) => {
    const isOccupied = (turns?.shifts || []).some(
      (shift) => shift.hourStart === slot.hourStart && !shift.softDelete
    );
    return { ...slot, occupied: isOccupied };
  });

  const filteredSlots = updatedSlots.filter((slot) => {
    return (
      !slot.occupied &&
      !slot?.exclude &&
      (dayjs().tz(tenantTimezone).add(2, 'hours') <=
        dayjs(slot?.hourStart).tz(tenantTimezone) ||
        dayjs().tz(tenantTimezone) < dayjs(date).tz(tenantTimezone))
    );
  });

  return {
    configSchedule: usualConfigSchedule ? usualConfigSchedule : configSchedule,
    filteredSlots,
  };
};
