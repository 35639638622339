import LayoutAdmin from '../layouts/LayoutAdmin';
import Home from '../pages/Home/Home';
import Error404 from '../pages/Error404';
import PortalHome from '../pages/PortalHome';
import DoctorSchedulePage from '../pages/DoctorSchedulePage/DoctorSchedulePage';
import ConfirmTurn from '../pages/ConfirmTurn/ConfirmTurn';

const routes = [
  {
    path: '/',
    exact: false,
    component: LayoutAdmin,
    routes: [
      {
        path: '/',
        exact: true,
        component: PortalHome,
      },
      {
        path: '/home',
        exact: true,
        component: Home,
      },
      {
        path: '/turnos/:id/:type',
        exact: true,
        component: DoctorSchedulePage,
      },
      {
        path: '/confirm-turn',
        exact: true,
        component: ConfirmTurn,
      },
      {
        component: Error404,
      },
    ],
  },
];

export default routes;
