import React from 'react';
import { Result, Descriptions, Card } from 'antd';
import {
  CheckCircleOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  UserOutlined,
  PhoneOutlined,
  TeamOutlined,
  SolutionOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useSelector } from 'react-redux';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('es');

const TurnDetail = ({ turnDetail, doctor, turnType, description }) => {
  const {
    tenant,
    tenantTimezone,
    direction,
    organization,
    organizationPhone,
    tenantLogo,
  } = useSelector((store) => store.tenantData);
  return (
    <div
      style={{
        maxWidth: '400px',
        margin: '10px auto',
        padding: '10px',
        boxSizing: 'border-box',
      }}
    >
      <Card
        style={{
          border: 'none',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          borderRadius: '10px',
          textAlign: 'center',
          padding: '8px !important',
          backgroundColor: 'rgba(245, 242, 242, 0.8)',
        }}
      >
        {/* Icono de éxito y título */}
        <Result
          style={{ padding: '0px' }}
          icon={
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <img
                src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/${
                  tenantLogo || 'logo'
                }.png`}
                alt="Logo"
                style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  objectFit: 'contain',
                  backgroundColor: '#f9f9f9',
                  padding: '10px',
                }}
              />
            </div>
          }
          title={
            <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>
              {organization}
            </h2>
          }
          subTitle={
            <p style={{ color: '#888' }}>
              <CheckCircleOutlined
                style={{ color: '#52c41a', marginRight: '5px' }}
              />
              Turno reservado con éxito.
            </p>
          }
        />

        {/* Información detallada */}
        <Descriptions
          column={1}
          style={{
            border: '1px solid rgb(199, 198, 198)',
            padding: '10px',
            borderRadius: '10px',
          }}
        >
          {/* Fecha */}
          <Descriptions.Item
            label={
              <>
                <CalendarOutlined style={{ marginRight: '5px' }} /> <b>Fecha</b>
              </>
            }
          >
            {dayjs(turnDetail?.hour).format('dddd D [de] MMMM')}
          </Descriptions.Item>

          {/* Hora */}
          <Descriptions.Item
            label={
              <>
                <ClockCircleOutlined style={{ marginRight: '5px' }} />{' '}
                <b>Hora</b>
              </>
            }
          >
            {dayjs(turnDetail?.hour).tz(tenantTimezone).format('HH:mm')} hs
          </Descriptions.Item>

          {/* Dirección */}
          <Descriptions.Item
            label={
              <>
                <EnvironmentOutlined style={{ marginRight: '5px' }} />{' '}
                <b>Dirección</b>
              </>
            }
          >
            {direction}
          </Descriptions.Item>

          {/* Médico */}
          {turnType === 'medico' && (
            <Descriptions.Item
              label={
                <>
                  <TeamOutlined style={{ marginRight: '5px' }} />
                  <b>Doctor</b>
                </>
              }
            >
              <span>
                {doctor?.name} {doctor?.lastname}
              </span>
            </Descriptions.Item>
          )}

          {turnDetail?.services?.length > 0 && (
            <Descriptions.Item
              label={
                <>
                  <SolutionOutlined style={{ marginRight: '5px' }} />
                  <b>Servicios</b>
                </>
              }
            >
              <ul style={{ padding: 0, margin: 0, marginLeft: '15px' }}>
                {turnDetail?.services?.map((service) => (
                  <li>
                    <span>
                      {service.name} -{' '}
                      {service?.price?.toLocaleString('es-AR', {
                        style: 'currency',
                        currency: 'ARS',
                        minimumFractionDigits: 2,
                      }) || ''}
                      <br /> {service?.description}
                    </span>
                  </li>
                ))}
              </ul>
            </Descriptions.Item>
          )}
          {description && (
            <Descriptions.Item
              label={
                <>
                  <InfoCircleOutlined style={{ marginRight: '5px' }} />
                  <b>Importante</b>
                </>
              }
            >
              <p style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
            </Descriptions.Item>
          )}
        </Descriptions>
      </Card>
    </div>
  );
};

export default TurnDetail;
