import React, { useState, useEffect, useMemo } from 'react';
import { DatePicker, Row, Col, Card, Button, Select } from 'antd';
import { getUsersTypeApi } from '../../api/user';
import ListTurns from '../../components/Ambulatory/ListTurns';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import Spin from '../../components/Spin';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'dayjs/locale/es';
import { getTurnPortalApi } from '../../api/portal';
import { onPatientTurnsRead, onPrimaryDataRead } from '../../actions';
import { WhatsAppOutlined } from '@ant-design/icons';
import './Turns.scss';
import TurnDetail from '../../components/TurnDetail/TurnDetail';
import LogoNombre from '../../assets/img/a1-8.png';
import { feriados } from '../../utils/constants';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { generateSlots } from '../../utils/functionTurns';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function Turns() {
  dayjs.locale('es');

  const {
    tenant,
    organization,
    studies,
    organizationPhone,
    tenantId,
    portalActive,
    tenantLogo,
    tenantTimezone,
  } = useSelector((store) => store.tenantData);
  const user = useSelector((store) => store.userData.user);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [slots, setSlots] = useState();
  const [date, setDate] = useState(
    dayjs().tz(tenantTimezone).format('YYYY-MM-DDTHH:mm:ss')
  );
  const [turn, setTurn] = useState([]);
  const [turnUser, setTurnUser] = useState(null);
  const [reload, setReload] = useState(false);
  const [size, setSize] = useState();
  const [turnType, setTurnType] = useState(null);
  const [turnDetail, setTurnDetail] = useState(false);
  const [data, setData] = useState();
  const [configSchedule, setConfigSchedule] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDataTurn = async () => {
      setLoading(true); // Comienza el cargado
      try {
        // Obtener los usuarios
        const userResponse = await getUsersTypeApi(tenantId);
        setUsers(userResponse.users);

        // Si no es tipo 'Medico', se obtiene información primaria
        if (turnType !== 'Medico') {
          dispatch(onPrimaryDataRead(window.location.pathname.split('/')[1]));
        }

        // Si el usuario tiene un ID, cargar turnos
        if (user?._id) {
          dispatch(onPatientTurnsRead(user._id));
        }

        // Solo proceder si `turnUser` está definido
        if (turnUser) {
          let userTurn;

          // Determinar el tamaño del slot dependiendo del tipo de turno
          if (turnType !== 'medico') {
            userTurn = studies.find((studie) => studie.id === turnUser);
          } else {
            userTurn = users.find((user) => user._id === turnUser);
          }

          setSize(userTurn?.defaultSizeSlot);

          // Obtener datos de los turnos
          const turnResponse = await getTurnPortalApi(turnUser, date, tenantId);
          setTurn(turnResponse?.turns);

          // Generar los horarios basados en el turno
          const response = await generateSlots({
            sizeSlot:
              turnResponse?.turns?.shifts.length > 0
                ? turnResponse?.turns.sizeSlot
                : userTurn?.defaultSizeSlot,
            turns: turnResponse?.turns,
            userTurn,
            date,
            tenantTimezone,
          });

          setSlots(response?.filteredSlots);
          setConfigSchedule(response?.configSchedule);
          setData(userTurn);
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false); // Termina el cargado, independientemente de si hubo error o no
      }
    };

    getDataTurn(); // Llamar a la función asíncrona para obtener los datos
    setReload(false);
  }, [date, turnUser, reload]);

  const disabledDate = (current) => {
    if (!current) return false;

    const isPastOrFuture =
      current.isBefore(dayjs().startOf('day')) ||
      current.diff(dayjs(), 'days') >=
        (data?.scheduleConfig?.quantityDaysPortal || 15);

    // Convertir `current` al mismo formato para comparar
    const formattedDate = current
      .startOf('day')
      .tz(tenantTimezone)
      .toISOString();

    // Verificar si la fecha es un día excluido
    const isExcludedDay = excludedDaysMap.has(formattedDate);

    const isScheduledDay = configSchedule[`${current.format('dd')}`]?.first;

    return (
      isPastOrFuture || // Deshabilita días pasados o fuera del rango permitido
      feriadosMap.has(`${current.month() + 1}-${current.date()}`) || // Deshabilita feriados
      !isScheduledDay || // Deshabilita días no programados
      isExcludedDay // Deshabilita días excluidos
    );
  };

  const feriadosMap = useMemo(() => {
    const map = new Map();

    feriados.forEach((feriado) => {
      const key = `${feriado.mes}-${feriado.dia}`;
      map.set(key, true);
    });
    return map;
  }, [feriados]);

  const excludedDaysMap = useMemo(() => {
    const map = new Map();
    data?.scheduleConfig?.excludeDays
      .filter((day) => !dayjs(day).isBefore(dayjs().startOf('day'))) // Filtra solo días futuros o presentes
      .forEach((day) => {
        const formattedDay = dayjs(day)
          .tz(tenantTimezone)
          .startOf('day')
          .toISOString(); // Convertir al mismo formato de comparación
        map.set(formattedDay, true);
      });
    return map;
  }, [data?.scheduleConfig?.excludeDays]);

  if (turnUser && !slots) return <Spin />;

  const formatTime = (date) =>
    new Date(date).toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
    });

  return turnDetail ? (
    <TurnDetail
      turnDetail={turnDetail}
      doctor={
        turnType === 'medico'
          ? users.find((user) => turnUser === user._id)
          : studies.find((studie) => turnUser === studie.id)
      }
      turnType={turnType}
      description={
        data?.scheduleConfig?.descriptionVisible &&
        data?.scheduleConfig?.description
      }
    />
  ) : (
    <div className="online">
      <Card title={`Turnos ${organization}`}>
        <Row gutter={[20, 24]} style={{ marginBottom: 5 }}>
          <Col sm={2} />

          <Col xs={24} sm={10}>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Busque por medico o especialidad"
              optionFilterProp="children"
              value={turnUser}
              onChange={(value, options) => {
                const turnType = options?.key ? 'studie' : 'medico';
                setTurnType(turnType);
                setTurnUser(value);
              }}
              allowClear
            >
              {users?.map((user, index) => {
                return (
                  <Select.Option value={user._id}>
                    {user.name} {user.lastname}{' '}
                    {user.medicalSpeciality && `- ${user.medicalSpeciality}`}
                  </Select.Option>
                );
              })}
              {studies?.map((studie, index) => (
                <Select.Option key={index} value={studie.id}>
                  Estudio - {studie.name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={24} sm={10}>
            <DatePicker
              inputReadOnly
              disabled={!turnUser}
              style={{ width: '100%' }}
              format="DD-MM-YYYY"
              placeholder="Seleccione una fecha.. (*)"
              onChange={(value) => {
                setDate(
                  dayjs(value).tz(tenantTimezone).format('YYYY-MM-DDTHH:mm:ss')
                );
              }}
              disabledDate={disabledDate}
              locale={locale}
              allowClear={false}
              value={dayjs(date).tz(tenantTimezone)}
            />
          </Col>
          <Col sm={2} />
        </Row>
        {turnUser ? (
          <div>
            <ListTurns
              slots={slots}
              turnId={turn?._id}
              turns={turn?.shifts}
              turnUser={turnUser}
              setReload={setReload}
              size={size}
              turnType={turnType}
              date={date}
              setTurnDetail={setTurnDetail}
              loadingParent={loading}
              doctorData={data}
            />

            <div style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '8pt', color: '#888' }}>
                🗓️ Horarios habituales:
              </span>
              <div>
                {data?.scheduleConfig?.hours?.map(
                  ({ start, end, days }, idx) => (
                    <p key={idx} style={{ fontSize: '7pt', color: '#888' }}>
                      ⏰ {days.join(', ').toUpperCase()}: {formatTime(start)} hs
                      - {formatTime(end)} hs
                    </p>
                  )
                )}
                <p style={{ fontSize: '6pt', color: '#888' }}>
                  Si ve turnos fuera de los horarios habituales, puede
                  solicitarlos. Se han habilitado turnos extra.
                </p>
              </div>
            </div>

            {data?.scheduleConfig?.description && (
              <div className="online-description">
                <h4>Información Importante</h4>
                <p>{data?.scheduleConfig?.description}</p>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="online-logo">
              <img
                src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/${
                  tenantLogo || 'logo'
                }.png`}
                alt="Logo"
              />
            </div>

            <div className="online-mini-logo">
              <p style={{ fontSize: '9pt' }}>
                En esta pagina solo aparecen los medicos y estudios que tienen
                su turnera web habilitada.
              </p>
              <p style={{ fontSize: '9pt' }}>
                Ante cualquier duda comunicarse con la institución o medico.
                Contacto:{' '}
                <Button type="link">
                  <a
                    href={`https://wa.me/${organizationPhone}?text=Hola%20${organization}`}
                    target="_blank"
                    referrerPolicy="origin"
                  >
                    <WhatsAppOutlined /> {organizationPhone}
                  </a>
                </Button>
              </p>
              <h2>Desarrollado por</h2>
              <a href="https://ampinasoft.com">
                <img src={LogoNombre} /> <h5>Visita Nuestra Web</h5>
              </a>
            </div>
          </>
        )}
      </Card>
    </div>
  );
}
