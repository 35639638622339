import React, { useEffect, useState, useRef } from 'react';
import {
  Layout,
  Menu,
  Row,
  Col,
  Typography,
  Card,
  Avatar,
  Select,
  Button,
  message,
  Dropdown,
} from 'antd';
import { useSelector } from 'react-redux';
import {
  InstagramOutlined,
  PlusOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LogoEmpresa from '../../assets/img/a1-8.png';
import NoAvatar from '../../assets/img/no-avatar.png';
import { getUsersTypeApi } from '../../api/user';
import { getServicesApi } from '../../api/service';
import './Services.scss';
import ServicesList from '../../components/ServicesList/ServicesList';
import Spin from '../../components/Spin';
import LoginForm from '../../components/LoginForm/LoginForm';
const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

const PortalHome = () => {
  const [professionals, setProfessionals] = useState([]);
  const [medicalSpecialities, setMedicalSpecialities] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  // Referencias a los componentes
  const inicioRef = useRef(null);
  const profesionalesRef = useRef(null);
  const serviciosRef = useRef(null);

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const {
    tenant,
    organization,
    tenantLogo,
    direction,
    organizationPhone,
    tenantId,
    studies,
    instagram,
    organizationDescription,
    portalActive,
  } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);
        const userResponse = await getUsersTypeApi(tenantId);

        // Extraer medicalSpeciality y filtrar únicos
        const uniqueSpecialities = [
          ...new Set(
            userResponse.users
              .map((user) => user.medicalSpeciality)
              .filter(Boolean)
          ),
        ];

        let serviceResponse = await getServicesApi(tenantId);

        setProfessionals(userResponse.users);
        setServicesData(serviceResponse?.services);
        setMedicalSpecialities(uniqueSpecialities);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    getUsers();
  }, []);

  const Detail = (professional, type) => {
    const formatTime = (date) =>
      new Date(date).toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
      });

    const Schedule = (hours) => {
      const visibleHours = expanded ? hours : hours?.slice(0, 2);

      return (
        <div>
          <span style={{ fontSize: '8pt', color: '#888' }}>
            🗓️ Horarios habituales:
          </span>
          <div>
            {visibleHours?.map(({ start, end, days }, idx) => (
              <p key={idx} style={{ fontSize: '7pt', color: '#888' }}>
                ⏰ {days.join(', ').toUpperCase()}: {formatTime(start)} hs -{' '}
                {formatTime(end)} hs
              </p>
            ))}
          </div>

          {hours?.length > 2 && (
            <Button
              onClick={() => setExpanded(!expanded)}
              type="text"
              className="toggle-button"
            >
              {expanded ? 'Ver menos' : 'Ver más'}
            </Button>
          )}
        </div>
      );
    };

    return (
      <Card
        style={{
          borderRadius: 10,
          backgroundColor: '#f0f2f5',
          margin: '5px',
        }}
        actions={[
          <Link
            to={`/turnos/${
              type === 'studie' ? professional.id : professional?._id
            }/${type}`}
          >
            <PlusOutlined /> Solicitar Turno
          </Link>,
        ]}
        hoverable
      >
        <div
          style={{ display: 'flex', alignItems: 'center', minHeight: '165px' }}
        >
          <Avatar
            alt="Foto perfil"
            src={
              professional?.profileImage
                ? `https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/profiles/${professional?.profileImage}`
                : NoAvatar
            }
            style={{
              width: '100px',
              height: '100px',
              objectFit: 'cover',
              borderRadius: '50%',
              marginRight: '16px', // Espaciado entre la foto y el texto
            }}
          />

          <div>
            <Title level={4} style={{ margin: 0 }}>
              {professional.name} {professional?.lastname}
            </Title>
            <Paragraph style={{ margin: 0, fontWeight: 400 }}>
              {professional?.medicalSpeciality}
            </Paragraph>
            <Paragraph style={{ margin: 0 }}>
              {Schedule(professional?.scheduleConfig?.hours)}
            </Paragraph>
          </div>
        </div>
      </Card>
    );
  };

  // Combinamos profesionales y especialidades en un solo array de opciones
  const options = [
    ...medicalSpecialities.map((speciality) => ({
      value: speciality,
      label: speciality,
      type: 'speciality',
    })),
    ...professionals.map((professional) => ({
      value: professional.name,
      label: `${professional.name} ${professional.lastname}`,
      type: 'professional',
    })),
    ...studies.map((study) => ({
      value: study.name,
      label: study.name,
      type: 'study',
    })),
  ];

  // Función de cambio de filtro
  const handleChange = (value) => {
    setFilter(value);
  };

  // Filtrado de resultados según el filtro seleccionado
  let filteredProfessionals = professionals?.filter(
    (professional) =>
      professional?.name?.toLowerCase().includes(filter?.toLowerCase()) ||
      professional?.medicalSpeciality
        ?.toLowerCase()
        .includes(filter?.toLowerCase())
  );

  let filteredStudies = studies?.filter((study) =>
    study?.name?.toLowerCase().includes(filter?.toLowerCase())
  );

  const Professional = ({ professionals, studies, service = false }) => (
    <div>
      {!service && (
        <Select
          showSearch
          style={{ width: '100%', marginBottom: 20 }}
          placeholder="Buscar por nombre, especialidad o estudio"
          onChange={(value) => handleChange(value)}
          value={filter}
          allowClear
        >
          {options.map((option, index) => (
            <Select.Option key={index} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      )}
      <Row gutter={16}>
        {professionals.map((professional, index) => (
          <Col key={index} xs={24} sm={12} lg={8}>
            {Detail(professional, 'medico')}
          </Col>
        ))}
        {studies?.map((studie, index) => (
          <Col key={index} xs={24} sm={12} lg={8}>
            {Detail(studie, 'studie')}
          </Col>
        ))}
      </Row>
    </div>
  );

  return (
    <Layout>
      {/* Header */}
      <Header
        style={{
          alignItems: 'center',
          background: '#002a52',
          padding: '0 20px',
        }}
      >
        {/* Menú de navegación */}
        <Menu
          mode="horizontal"
          style={{ backgroundColor: '#002a52', color: '#fff' }}
        >
          <Menu.Item key="2" onClick={() => handleScroll(profesionalesRef)}>
            Profesionales
          </Menu.Item>
          <Menu.Item key="3" onClick={() => handleScroll(serviciosRef)}>
            Servicios
          </Menu.Item>
          {portalActive && (
            <Dropdown
              overlay={<LoginForm />}
              trigger={['click']}
              placement="bottomCenter"
            >
              <Menu.Item key="4">Mis estudios</Menu.Item>
            </Dropdown>
          )}
        </Menu>
      </Header>

      {/* Content */}
      <Content style={{ padding: '20px 50px' }} ref={inicioRef}>
        <Row gutter={32} align="middle" style={{ marginBottom: 50 }}>
          {/* Imagen del logo */}
          <Col xs={24} md={8} style={{ textAlign: 'center' }}>
            <div
              style={{
                width: 150,
                height: 150,
                borderRadius: '50%',
                overflow: 'hidden',
                border: '3px solid #ddd',
                margin: '0 auto',
              }}
            >
              <img
                src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/${
                  tenantLogo || 'logo'
                }.png`}
                alt="Logo"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
            {/* Nombre del centro */}
            <Title level={3}> {organization}</Title>

            {/* Ubicación y WhatsApp */}
            <div
              style={{
                marginTop: '5px',
                color: '#888',
                fontSize: '14px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>📍</span> {direction}
              </p>
              <p
                style={{
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '5px',
                }}
              >
                <WhatsAppOutlined
                  style={{ color: 'green', marginRight: '5px' }}
                />{' '}
                <a
                  href={`https://wa.me/${organizationPhone}?text=Hola%20${organization}`}
                  target="_blank"
                  referrerPolicy="origin"
                  style={{ color: '#888' }}
                >
                  {organizationPhone}
                </a>
              </p>
              {instagram && (
                <p
                  style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '5px',
                  }}
                >
                  <a
                    href={`https://instagram.com/${instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#888' }}
                  >
                    <InstagramOutlined style={{ color: '#E1306C' }} />{' '}
                    {instagram}
                  </a>
                </p>
              )}
            </div>
          </Col>

          {/* Presentación */}
          <Col xs={24} md={16}>
            <Title level={2}>Bienvenidos</Title>
            <Paragraph>{organizationDescription}</Paragraph>
          </Col>
        </Row>
        {loading ? (
          <Spin />
        ) : (
          <div>
            {/* Profesionales */}
            <div style={{ marginBottom: 50 }} ref={profesionalesRef}>
              <Title level={3}>Nuestro Equipo</Title>
              {Professional({
                professionals: filter ? filteredProfessionals : professionals,
                studies: filter ? filteredStudies : studies,
                service: false,
              })}
            </div>

            {/* Servicios */}
            <div>
              <Title level={3}>Servicios</Title>
              <div ref={serviciosRef}>
                <ServicesList
                  servicesData={servicesData}
                  professionals={professionals}
                  component={Professional}
                />
              </div>
            </div>
          </div>
        )}
      </Content>

      {/* Footer */}
      <Footer
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          padding: '20px',
        }}
      >
        {/* Texto principal */}
        <div>
          © 2024 Portal de Turnos - Un desarrollo de AmpinaSoft. ¡Simplificando
          tu gestión de turnos!
        </div>

        {/* Firma con logo y enlace */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <img src={LogoEmpresa} alt="AmpinaSoft" style={{ height: '30px' }} />
          <a
            href="https://ampinasoft.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1890ff',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
          >
            Descubre nuestros servicios
          </a>
        </div>
      </Footer>
    </Layout>
  );
};

export default PortalHome;
