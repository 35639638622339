// import React, { useMemo } from 'react';
// import { Calendar } from 'antd';
// import dayjs from 'dayjs';
// import 'dayjs/locale/es';
// import timezone from 'dayjs/plugin/timezone';
// import utc from 'dayjs/plugin/utc';
// import './CustomCalendar.scss'; // Archivo de estilos
// import { feriados } from '../../utils/constants';

// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.locale('es');

// export default function CustomCalendar({
//   onDaySelect,
//   tenantTimezone = 'America/Argentina/Buenos_Aires',
//   scheduleConfig,
// }) {
//   // Map de feriados
//   const feriadosMap = useMemo(() => {
//     const map = new Map();
//     feriados.forEach((feriado) => {
//       const key = `${feriado.mes}-${feriado.dia}`;
//       map.set(key, true);
//     });
//     return map;
//   }, [feriados]);

//   // Map de días excluidos
//   const excludedDaysMap = useMemo(() => {
//     const map = new Map();
//     scheduleConfig?.excludedDays
//       ?.filter((day) => !dayjs(day).isBefore(dayjs().startOf('day'))) // Filtra solo días futuros o presentes
//       ?.forEach((day) => {
//         const formattedDay = dayjs(day)
//           .tz(tenantTimezone)
//           .startOf('day')
//           .toISOString(); // Convertir al mismo formato de comparación
//         map.set(formattedDay, true);
//       });
//     return map;
//   }, [scheduleConfig.excludedDays, tenantTimezone]);

//   // Lógica para deshabilitar fechas
//   const disabledDate = (current) => {
//     if (!current) return false;

//     const isPastOrFuture =
//       current.isBefore(dayjs().startOf('day')) || // Días pasados
//       current.diff(dayjs(), 'days') >= scheduleConfig?.quantityDaysPortal; // Rango máximo de días permitidos

//     // Formato de la fecha para comparación
//     const formattedDate = current
//       .startOf('day')
//       .tz(tenantTimezone)
//       .toISOString();

//     // Verificaciones adicionales
//     const isExcludedDay = excludedDaysMap.has(formattedDate);
//     const isScheduledDay = scheduleConfig?.[`${current.format('dd')}`]?.first;

//     return (
//       isPastOrFuture || // Deshabilita días pasados o fuera de rango
//       feriadosMap.has(`${current.month() + 1}-${current.date()}`) || // Días feriados
//       !isScheduledDay || // Días no programados
//       isExcludedDay // Días excluidos
//     );
//   };

//   // Evento de selección de día
//   const handleSelect = (value) => {
//     if (!disabledDate(value)) {
//       onDaySelect(value.format('YYYY-MM-DD'));
//     }
//   };

//   return (
//     <div className="custom-calendar">
//       <Calendar
//         fullscreen={false} // Hace el calendario compacto
//         disabledDate={disabledDate}
//         onSelect={handleSelect}
//         headerRender={({ value, type, onChange, onTypeChange }) => (
//           <div className="calendar-header">
//             <span className="calendar-header-title">
//               {value.format('MMMM YYYY')}
//             </span>
//           </div>
//         )}
//       />
//     </div>
//   );
// }

import React, { useState, useMemo } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './CustomCalendar.scss'; // Importa los estilos SCSS
import { feriados } from '../../utils/constants';
import dayjs from 'dayjs';

dayjs.locale('es');

export default function CustomCalendar({
  onDaySelect,
  scheduleConfig,
  tenantTimezone,
  configSchedule,
}) {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [selectedDay, setSelectedDay] = useState(dayjs());

  const handleDayClick = (day) => {
    setSelectedDay(day);
    onDaySelect(day);
  };

  // Map de feriados
  const feriadosMap = useMemo(() => {
    const map = new Map();
    feriados.forEach((feriado) => {
      const key = `${feriado.mes}-${feriado.dia}`;
      map.set(key, true);
    });
    return map;
  }, [feriados]);

  // Map de días excluidos
  const excludedDaysMap = useMemo(() => {
    const map = new Map();
    scheduleConfig?.excludeDays
      ?.filter((day) => !dayjs(day).isBefore(dayjs().startOf('day'))) // Filtra solo días futuros o presentes
      ?.forEach((day) => {
        const formattedDay = dayjs(day)
          .tz(tenantTimezone)
          .startOf('day')
          .toISOString(); // Convertir al mismo formato de comparación
        map.set(formattedDay, true);
      });
    return map;
  }, [scheduleConfig?.excludeDays, tenantTimezone]);

  // Lógica para deshabilitar fechas
  const disabledDate = (current) => {
    if (!current) return false;

    const isPastOrFuture =
      current.isBefore(dayjs().startOf('day')) || // Días pasados
      current.diff(dayjs(), 'days') >= scheduleConfig?.quantityDaysPortal; // Rango máximo de días permitidos

    // Formato de la fecha para comparación
    const formattedDate = current
      .startOf('day')
      .tz(tenantTimezone)
      .toISOString();

    // Verificaciones adicionales
    const isExcludedDay = excludedDaysMap.has(formattedDate);
    const isScheduledDay = configSchedule?.[`${current.format('dd')}`]?.first;

    return (
      isPastOrFuture || // Deshabilita días pasados o fuera de rango
      feriadosMap.has(`${current.month() + 1}-${current.date()}`) || // Días feriados
      !isScheduledDay || // Días no programados
      isExcludedDay // Días excluidos
    );
  };

  // Funciones para cambiar el mes
  const goToPreviousMonth = () => {
    setCurrentDate((prevDate) => prevDate.clone().subtract(1, 'month'));
    setSelectedDay(null); // Reinicia la selección al cambiar de mes
  };

  const goToNextMonth = () => {
    setCurrentDate((prevDate) => prevDate.clone().add(1, 'month'));
    setSelectedDay(null); // Reinicia la selección al cambiar de mes
  };

  return (
    <div className="custom-calendar">
      {/* Header del calendario */}
      <div className="calendar-header">
        <LeftOutlined className="nav-arrow" onClick={goToPreviousMonth} />
        <h3 className="month-label">{currentDate.format('MMMM YYYY')}</h3>
        <RightOutlined className="nav-arrow" onClick={goToNextMonth} />
      </div>

      {/* Días de la semana */}
      <div className="weekdays">
        {['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'].map((day) => (
          <div key={day} className="weekday">
            {day}
          </div>
        ))}
      </div>

      <div className="calendar-grid">
        {[
          ...Array(currentDate.startOf('month').day()), // Espacios vacíos para los días antes del primer día del mes
          ...Array(currentDate.daysInMonth()), // Días del mes
        ].map((_, i) => {
          const day = currentDate
            .clone()
            .startOf('month')
            .add(i - currentDate.startOf('month').day(), 'day');

          // Comprobar si el día está deshabilitado
          const isDisabled =
            i < currentDate.startOf('month').day() || disabledDate(day);

          return (
            <div
              key={i}
              className={`calendar-day ${isDisabled ? 'disabled' : ''} ${
                selectedDay && day.isSame(selectedDay, 'day') ? 'selected' : ''
              }`}
              onClick={() => !isDisabled && handleDayClick(day)}
              style={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                backgroundColor: isDisabled
                  ? '#cccccc'
                  : selectedDay && day.isSame(selectedDay, 'day')
                  ? '#1890ff'
                  : '',
                color: isDisabled ? '#aaa' : '',
              }}
            >
              {i >= currentDate.startOf('month').day() ? day.date() : ''}
            </div>
          );
        })}
      </div>
    </div>
  );
}
