import { basePath, apiVersion } from './config';

export async function signUpApiPortal(data) {
  const url = `${basePath}/${apiVersion}/sign-up-portal`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    if (result_1.user) {
      return {
        ok: true,
        message: 'Usuario creado correctamente',
        user: result_1.user,
      };
    }
    return { ok: false, message: result_1.message };
  } catch (err) {
    return { ok: false, message: err.message };
  }
}

export async function signInApiPortal({ data, tenantId }) {
  const url = `${basePath}/${apiVersion}/sign-in-portal?tenant=${tenantId}`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function getUserDataApiPortal(token) {
  const url = `${basePath}/${apiVersion}/get-data-portal`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();

    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function getTurnPortalApi(user, date, tenantId) {
  const url = `${basePath}/${apiVersion}/turn-portal/${user}&${date}?tenant=${tenantId}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getPatientTurnsPortalApi({
  token,

  patientId,
}) {
  const url = `${basePath}/${apiVersion}/turns-patient-portal?patientId=${patientId}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function recoverPasswordApiPortal(data) {
  const url = `${basePath}/${apiVersion}/user-portal-recover-password`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    if (result_1.user) {
      return {
        ok: true,
        message: `Para recuperar cuenta revisar email: ${result_1.user.email}`,
      };
    }
    return { ok: false, message: result_1.message };
  } catch (err) {
    return { ok: false, message: err.message };
  }
}

export async function getFeriadosApi(year) {
  const url = `http://nolaborables.com.ar/api/v2/feriados/${year} `;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
