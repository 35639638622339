import React, { useEffect, useState } from 'react';
import {
  Button,
  List,
  Tooltip,
  Radio,
  Dropdown,
  Menu,
  Space,
  Breadcrumb,
} from 'antd';
import {
  EyeOutlined,
  FilePdfOutlined,
  LeftOutlined,
  LoadingOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../api/auth';
import { getPdfApi } from '../../api/cloudStorage';
import dayjs from 'dayjs';
import PdfViewer from '../../components/PdfViewer';
import Spin from '../../components/Spin';
import { PageListAndView } from '../../components/ListAndDetail';
import { getPatientStudiesApi } from '../../api/patient';
import './PdfReports.scss';
import Cardio from '../../assets/img/cardiogram.png';
import DiagnosticoImagen from '../../assets/img/x-rays.png';
import Laboratorio from '../../assets/img/laboratory.png';
import { useSelector } from 'react-redux';

export default function PdfReports() {
  const [pdfReports, setPdfReports] = useState();
  const [pdfReportFilter, setPdfReportFilter] = useState('Todos');
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [pdf, setPdf] = useState();
  const [pdfName, setPdfName] = useState();
  const [extension, setExtension] = useState();

  const id = useSelector((store) => store.userData.user._id);
  const { tenantId } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      if (token) {
        getPatientStudiesApi(id, token)
          .then((response) => setPdfReports(response.studies.reverse()))
          .catch((err) => console.log(err));
        setLoadingDownload(false);
      }
    };
    getData();
  }, [id, loadingDownload]);

  const viewPdf = async (pdfReport) => {
    const token = await getAccessTokenApi();
    setLoadingUpload(true);
    setPdfName(pdfReport.title);
    await getPdfApi(
      token,
      pdfReport?.id ? pdfReport.id : id,
      pdfReport.title || pdfReport,
      tenantId
    ).then((response) => {
      setPdf(response.url);
      setExtension(response.url.split('.').pop());
      setLoadingUpload(false);
      if (window.screen.width <= 800) {
        window.open(response.url);
      }
    });
  };

  const filterPdfType = async (value) => {
    value === 'Todos'
      ? setPdfReportFilter(value)
      : setPdfReportFilter(pdfReports?.filter((pdf) => pdf?.type === value));
  };

  return (
    <div className="evolution">
      {pdf && (
        <div className="evolution-header">
          <Breadcrumb
            items={[
              {
                onClick: () => setPdf(),
                title: (
                  <span>
                    <LeftOutlined /> Ver todos los informes
                  </span>
                ),
              },
            ]}
          />
        </div>
      )}

      <PageListAndView
        datesTitle="Informes complementarios"
        componentDate={
          !pdf && (
            <List
              itemLayout="horizontal"
              dataSource={
                pdfReportFilter === 'Todos' ? pdfReports : pdfReportFilter
              }
              renderItem={(pdfReport) => (
                <Item
                  pdfReport={pdfReport}
                  viewPdf={viewPdf}
                  loadingUpload={loadingUpload}
                  id={id}
                  setLoadingDownload={setLoadingDownload}
                />
              )}
              locale={{ emptyText: 'No se han encontrado datos ' }}
              loading={
                !PdfReports && {
                  style: { width: '100%', padding: '200px 0' },
                  size: 'large',
                  indicator: <LoadingOutlined />,
                }
              }
            />
          )
        }
        detailTitle="Detalle informe"
        data={pdf}
        setPdf={setPdf}
        componentDetail={
          loadingUpload ? (
            <Spin />
          ) : pdf && extension === 'pdf' ? (
            <PdfViewer pdf={pdf} />
          ) : (
            <embed src={pdf} style={{ width: '100%', height: '100%' }} />
          )
        }
        extra={
          <div>
            <Tooltip title="Descargar">
              <a href={pdf} target="_blank" referrerPolicy="origin">
                <Button type="link" size="large" disabled={!pdf && true}>
                  Descargar <FilePdfOutlined />
                </Button>
              </a>
            </Tooltip>
          </div>
        }
        extraDate={
          <Tooltip title="Filtrar por">
            <Dropdown
              trigger="click"
              overlay={
                <Menu>
                  <Menu.Item>
                    <Radio.Group
                      onChange={(e) => {
                        filterPdfType(e.target.value);
                      }}
                    >
                      <Space direction="vertical">
                        <Radio value={'Todos'}>Todos</Radio>
                        <Radio value={'Laboratorio'}>Laboratorio</Radio>
                        <Radio value={'Cardio'}>Cardio</Radio>
                        <Radio value={'Imagen'}>Imagen</Radio>
                        <Radio value={false}>Otros</Radio>
                      </Space>
                    </Radio.Group>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="link">
                <MoreOutlined style={{ color: '#1764ab' }} />
              </Button>
            </Dropdown>
          </Tooltip>
        }
      />
    </div>
  );
}

function Item(props) {
  const { pdfReport, viewPdf, loadingUpload, id, setLoadingDownload } = props;

  return (
    <List.Item
      style={
        pdfReport?.type === 'Laboratorio'
          ? { backgroundColor: '#c2e1ff', padding: 5 }
          : pdfReport?.type === 'Cardio'
          ? { padding: 5, backgroundColor: '#ffbfb0' }
          : pdfReport?.type === 'Imagen'
          ? { padding: 5, backgroundColor: '#dfcae1' }
          : { padding: 5 }
      }
      actions={[
        pdfReport?.type === 'Cardio' ? (
          <img src={Cardio} style={{ height: '30px' }} />
        ) : pdfReport?.type === 'Imagen' ? (
          <img
            src={DiagnosticoImagen}
            style={{ height: '25px', width: '30px' }}
          />
        ) : (
          pdfReport?.type === 'Laboratorio' && (
            <img src={Laboratorio} style={{ height: '30px' }} />
          )
        ),
        <Tooltip title="Ver Detalle">
          <Button
            type="default"
            size="small"
            onClick={() => {
              viewPdf(pdfReport);
            }}
            disabled={loadingUpload}
          >
            <EyeOutlined />
          </Button>
        </Tooltip>,
      ]}
    >
      <List.Item.Meta
        title={pdfReport.title || pdfReport}
        description={
          pdfReport?.date && dayjs(pdfReport.date).format('DD-MM-YY')
        }
      />
    </List.Item>
  );
}
