import React, { useState, useEffect } from 'react';
import {
  Button,
  Tag,
  Input,
  Form,
  Row,
  Col,
  Select,
  Radio,
  Affix,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { addTurnPortalApi, updateTurnPortalApi } from '../../../api/turn';
import { getSocialWorksApi } from '../../../api/patient';
import { countryCodes } from '../../../utils/constants';
import { getServicesApi } from '../../../api/service';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CustomLoading from '../../CustomLoading/CustomLoading';

import { useHistory } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function NewTurnForm({
  date,
  doctor,
  scheduleConfig,
  turnId,
  setReload,
  turnUser,
  turnType,
  hourStart,
  setIsVisibleModal,
  size,
  turns,
  setTurnDetail,
}) {
  const [loading, setLoading] = useState(false);
  const [socialWorks, setSocialWorks] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [turnsSocialWorks, setTurnsSocialWorks] = useState([]);

  const { tenantId, tenantTimezone, countryCode } = useSelector(
    (store) => store.tenantData
  );
  const [patientForm] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    const getData = () => {
      getSocialWorksApi(tenantId)
        .then((response) => {
          setSocialWorks(response?.socialWorks);
        })
        .catch((err) => console.log(err));

      getServicesApi(tenantId)
        .then((response) => {
          setServicesData(response?.services);
        })
        .catch((err) => console.log(err));
    };

    getData();
  }, []);

  useEffect(() => {
    // Crear un mapa para contar los turnos por obra social
    const socialWorkCount = turns?.reduce((acc, turn) => {
      // Solo contar turnos que no estén eliminados (softDelete: false)
      if (!turn.softDelete) {
        // Incrementar el contador para la obra social
        acc[turn.socialWork] = (acc[turn.socialWork] || 0) + 1;

        // Incrementar el contador total
        acc.total =
          dayjs(hourStart).tz(tenantTimezone).unix() >
          dayjs().tz(tenantTimezone).add(2, 'hours').unix()
            ? (acc.total || 0) + 1
            : acc.total || 0;
      }
      return acc;
    }, {});

    setTurnsSocialWorks(socialWorkCount || { total: 0 });

    patientForm.setFieldsValue({ socialWork: null });
  }, [turns, date, turnUser]);

  const onFinish = async (values) => {
    setLoading(true);
    message.loading('Solicitando turno, aguarde un momento...', 0);

    values.socialWork = values.socialWork === false ? '' : values.socialWork;

    let notificationPhone = {
      countryCode: values?.countryCode,
      regionCode: values?.regionCode,
      number: values?.number,
    };

    let services = servicesData?.filter((service) => {
      if (values?.studiesTypes?.find((e) => e === service.name)) {
        return { service: service.name, price: service?.price };
      }
    });

    let price =
      services?.reduce(
        (accumulator, currentValue) =>
          (accumulator || 0) + parseFloat(currentValue.price || 0),
        0
      ) || null;

    const params = {
      turnDetail: {
        hour: hourStart,
        services: services || null,
      },
      doctor,
      turnType,
      description:
        scheduleConfig?.descriptionVisible && scheduleConfig?.description,
    };

    turnId
      ? await updateTurnPortalApi({
          tenantId,
          turnId,
          dataTurn: {
            hourStart: hourStart,
            socialWork: values.socialWork,
            name: values.name,
            dni: values.dni,
            notificationPhone: notificationPhone,
            email: values.email,
            price: price,
            portal: true,
            studies: services,
            description: values?.description,
            turnType,
            user: turnUser,
          },
        })
          .then(async (response) => {
            message.destroy();

            if (response?.code !== 200) {
              await message.warning(response.message, 5);

              setReload(true);
            } else {
              await message.success(response.message, 1);
              setTurnDetail
                ? setTurnDetail({
                    hour: dayjs(hourStart),
                    services: services || null,
                  })
                : history.push(`/confirm-turn`, params);
            }
          })
          .catch((err) => message.error('Error del servidor'))
      : await addTurnPortalApi({
          tenantId,
          data: {
            date: date,
            user: turnUser,
            sizeSlot: size,
            turnType,
            dataTurn: {
              hourStart: hourStart,
              socialWork: values.socialWork,
              name: values.name,
              dni: values.dni,
              notificationPhone: notificationPhone,
              email: values.email,
              price: price,
              portal: true,
              studies: services,
              description: values?.description,
            },
          },
        })
          .then(async (response) => {
            if (response?.code !== 200) {
              await message.warning(response.message, 5);

              setReload(true);
            } else {
              await message.success(response.message, 1);

              setTurnDetail
                ? setTurnDetail({
                    hour: dayjs(hourStart),
                    services: services || null,
                  })
                : history.push(`/confirm-turn`, params);
            }
          })
          .catch((err) => message.error('Error del servidor'));
    message.destroy();

    setIsVisibleModal(false);
    setLoading(false);
  };

  const filterSocialWorks = (socialWorks, scheduleConfig) => {
    if (
      !scheduleConfig?.coverageType ||
      scheduleConfig?.coverageType === 'acceptAll'
    ) {
      return socialWorks;
    }

    const day = dayjs(date).format('dd');

    // Crear el diccionario de días por obra social
    const configMap = scheduleConfig.socialWorksConfig.reduce((map, config) => {
      if (!map[config.socialWork]) {
        map[config.socialWork] = [];
      }
      map[config.socialWork].push(config);
      return map;
    }, {});

    const dataFilter = socialWorks
      .filter((socialWork) => {
        const daysForWork = configMap[socialWork.name] || false;

        return (
          !daysForWork ||
          !daysForWork?.every((config) => config.exclusion === true)
        ); // Filtrar las obras sociales excluidas
      })
      .map((socialWork) => {
        const daysForWork = configMap[socialWork.name] || false;

        // Filtro solo la del dia especifico
        const filteredConfig =
          daysForWork?.length > 0
            ? daysForWork?.find((config) => config?.days?.includes(day))
            : false;

        const isEnabled = daysForWork
          ? filteredConfig?.days?.includes(day)
          : true; // En caso de no tener aceptar todas
        const available =
          filteredConfig?.quantity !== null
            ? filteredConfig?.quantity -
              (turnsSocialWorks[socialWork.name] || 0)
            : false;

        return {
          ...socialWork,
          disabled: !isEnabled, // Deshabilitar si no está habilitado para el día actual
          daysForWork:
            filteredConfig === undefined
              ? {
                  days: Array.from(
                    new Set(daysForWork?.flatMap((config) => config.days))
                  ),
                }
              : filteredConfig,
          available,
        };
      });

    if (scheduleConfig?.coverageType === 'onlyListed') {
      return dataFilter.filter((socialWork) =>
        scheduleConfig.socialWorksConfig.some(
          (config) => config.socialWork === socialWork.name
        )
      );
    }

    return dataFilter;
  };

  if (loading) {
    return <CustomLoading loading={loading} />;
  }

  return (
    <div>
      <Form onFinish={onFinish} form={patientForm}>
        <Row gutter={24}>
          <Col xs={24} md={8} lg={8}>
            <Form.Item
              name="dni"
              rules={[
                {
                  required: true,
                  message: 'Debe cargar el dni. Sin puntos',
                },
                {
                  min: 5,
                  message: 'El DNI debe tener al menos 5 caracteres.',
                },
              ]}
              label={
                countryCode === '+54' ? 'DNI' : 'DNI / Cedula de Identidad'
              }
              labelCol={{ span: 24 }}
            >
              <Input placeholder="34256389" type="number" inputMode="numeric" />
            </Form.Item>
          </Col>
          <Col xs={24} md={16} lg={16}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Debe cargar el Apellido y Nombre',
                },
              ]}
              label="Apellido y Nombre"
              labelCol={{ span: 24 }}
            >
              <Input
                placeholder="Apellido Nombre"
                style={{ textTransform: 'capitalize' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={8}>
            <Form.Item
              name="socialWork"
              rules={[
                { required: true, message: 'Debe cargar la obra social' },
              ]}
              label="Obra social / Seguro"
              labelCol={{ span: 24 }}
            >
              <Select
                placeholder="Obra Social"
                showSearch
                optionFilterProp="value"
              >
                {filterSocialWorks(socialWorks, scheduleConfig).map(
                  (filteredSocialWork, index) => (
                    <Select.Option
                      key={filteredSocialWork.name}
                      value={filteredSocialWork.name}
                      disabled={
                        filteredSocialWork?.disabled ||
                        filteredSocialWork?.available === 0
                      }
                    >
                      <span style={{ textTransform: 'uppercase' }}>
                        {filteredSocialWork.name}
                      </span>

                      {(() => {
                        if (filteredSocialWork?.disabled) {
                          return ` (Atiende los: ${filteredSocialWork?.daysForWork?.days.join(
                            ', '
                          )})`;
                        }

                        if (filteredSocialWork?.available === 0) {
                          return (
                            <Tag color="red" style={{ marginLeft: '8px' }}>
                              Disponibles 0 - Atiende los:{' '}
                              {filteredSocialWork?.daysForWork?.days.join(', ')}
                            </Tag>
                          );
                        } else {
                          return (
                            <Tag
                              color={
                                filteredSocialWork?.available > 5 ||
                                !filteredSocialWork?.available
                                  ? 'success'
                                  : filteredSocialWork?.available >= 3
                                  ? 'gold'
                                  : 'volcano'
                              }
                              style={{ marginLeft: '8px' }}
                            >
                              {filteredSocialWork?.available > 5 ||
                              !filteredSocialWork?.available
                                ? 'Disponible'
                                : `Disponibles: ${filteredSocialWork?.available}`}
                            </Tag>
                          );
                        }

                        return '';
                      })()}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={8}>
            <Form.Item
              name="email"
              label="Correo electronico"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message:
                    'Debe cargar un mail valido. (sin espacios al final)',
                },
              ]}
              tooltip="En caso de que su turno sea cancelado recibira una notificación via mail"
            >
              <Input placeholder="Correo electronico" inputMode="email" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={8}>
            <Form.Item label="Teléfono de contacto" labelCol={{ span: 24 }}>
              <Row>
                <Col xs={12} xl={6}>
                  <Form.Item
                    name="countryCode"
                    noStyle
                    initialValue={countryCode}
                  >
                    <Select style={{ fontSize: '8pt' }}>
                      {countryCodes?.map((country) => (
                        <Select.Option value={country.dial_code}>
                          {country.emoji}
                          {country.dial_code}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {countryCode === '+54' && (
                  <Col xs={12} xl={6}>
                    <Form.Item
                      name="regionCode"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: 'Debe completar el codigo de area. Ej 385',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Cod Area"
                        help="385"
                        maxLength={4}
                        type="number"
                        inputMode="tel"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} xl={12}>
                  <Form.Item
                    name="number"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: 'Debe completar su nro. Ej 4889915',
                      },
                    ]}
                  >
                    <Input
                      addonBefore={countryCode === '+54' && '15'}
                      placeholder="Nro Tel."
                      maxLength={countryCode === '+54' && 7}
                      type="number"
                      inputMode="tel"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          {scheduleConfig?.service?.length > 0 ? (
            <Col xs={24}>
              <Form.Item
                name="studiesTypes"
                label="Servicios"
                labelCol={{ span: 24 }}
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Seleccione servicios.. (*)"
                  showSearch={false}
                  inputMode="none"
                >
                  {servicesData?.map((service) => {
                    if (
                      service?.public &&
                      scheduleConfig.service?.find((e) => e === service._id)
                    ) {
                      return (
                        <Select.Option key="studie" value={service?.name}>
                          {service?.name}
                        </Select.Option>
                      );
                    }
                  })}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            turnType === 'medico' && (
              <Col xs={24} md={8} lg={8}>
                <Form.Item
                  name="description"
                  label="Tipo de turno (opcional)"
                  labelCol={{ span: 24 }}
                >
                  <Radio.Group>
                    <Radio value="Primera Vez">Primera Vez</Radio>
                    <Radio value="Consulta">Consulta</Radio>
                    <Radio value="Consulta + Practica">
                      Consulta + Practica
                    </Radio>{' '}
                  </Radio.Group>
                </Form.Item>
              </Col>
            )
          )}
        </Row>

        <Row gutter={24} style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Form.Item>
              <Affix offsetBottom={10}>
                <Button
                  type="default"
                  htmlType="submit"
                  style={{
                    width: '100%',
                  }}
                  disabled={loading}
                  loading={loading}
                >
                  Solicitar turno
                </Button>
              </Affix>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
