import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TurnDetail from '../../components/TurnDetail/TurnDetail';
import { useSelector } from 'react-redux';
export default function ConfirmTurn() {
  const history = useHistory();
  const location = useLocation();

  const [secondsLeft, setSecondsLeft] = useState(25); // Contador inicial en 30 segundos
  const { tenantId } = useSelector((store) => store.tenantData);

  // Redirige si no hay datos en location.state
  useEffect(() => {
    if (!location.state) {
      history.replace(`/`);
    } else {
      // Configura el contador regresivo y redirección
      const timer = setInterval(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000);

      const redirectTimer = setTimeout(() => {
        history.replace(`/`);
      }, 25000);

      return () => {
        clearInterval(timer); // Limpia el contador
        clearTimeout(redirectTimer); // Limpia la redirección
      };
    }
  }, [history, location.state]);

  // Extraer datos de location.state
  const turnDetail = location?.state?.turnDetail;

  const turnType = location?.state?.turnType || '';

  const doctor = location?.state?.doctor || '';

  const description = location?.state?.description || null;

  // Renderiza el componente solo si turnDetail existe
  if (!turnDetail) return null;

  return (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <h4 style={{ marginTop: '20px' }}>¡Turno confirmado!</h4>
      <p style={{ fontSize: '8pt' }}>
        Su confirmación de turno ha sido enviada a su correo electrónico.
      </p>
      <p style={{ fontSize: '8pt' }}>
        Será redirigido a la página de inicio en <strong>{secondsLeft}</strong>{' '}
        segundos...
      </p>
      <TurnDetail
        turnDetail={turnDetail}
        doctor={doctor}
        turnType={turnType}
        description={description}
      />
    </div>
  );
}
