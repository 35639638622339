import React, { useEffect, useState } from 'react';
import { Tag, Card } from 'antd';
import { useSelector } from 'react-redux';

const ServicesList = ({ servicesData, professionals, component }) => {
  const [selectedService, setSelectedService] = useState(null);
  const [profesionalsFilter, setProfesionalsFilter] = useState([]);
  const [studiesFilter, setStudiesFilter] = useState([]);

  const { studies } = useSelector((store) => store.tenantData);

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  useEffect(() => {
    setProfesionalsFilter(
      professionals?.filter((professional) =>
        professional?.scheduleConfig?.service?.some(
          (service) => service == selectedService?._id
        )
      )
    );

    setStudiesFilter(
      studies?.filter((study) =>
        study?.scheduleConfig?.service?.some(
          (service) => service == selectedService?._id
        )
      )
    );
  }, [selectedService]);

  return (
    <div>
      <div className="tags-container" style={{ marginBottom: '20px' }}>
        {servicesData?.map((service, index) => (
          <Tag
            key={index}
            color="blue"
            style={{ cursor: 'pointer' }}
            onClick={() => handleServiceClick(service)}
          >
            {service.name}
          </Tag>
        ))}
      </div>

      {selectedService && (
        <Card title={selectedService?.name} style={{ marginTop: '20px' }}>
          <p>{selectedService.description}</p>
          {component({
            professionals: profesionalsFilter,
            studies: studiesFilter,
            service: true,
          })}
        </Card>
      )}
    </div>
  );
};

export default ServicesList;
