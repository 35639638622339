import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { signInApiPortal } from '../../api/portal';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAccessTokenApi } from '../../api/auth';

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const { tenantId } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();

      if (token) {
        history.push(`/home`);
      }
    };

    getData();
  }, []);

  const handleFinish = async (values) => {
    setLoading(true);

    const result = await signInApiPortal({ data: values, tenantId });

    if (result?.message) {
      message.error(result.message);
      setLoading(false);
    } else {
      const { accessToken, refreshToken } = result;
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      message.success('Login correcto');

      history.push(`/home`);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        padding: '16px',
        background: '#fff',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="dni"
          rules={[
            {
              required: true,
              message: 'Debe ingresar su DNI',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="DNI"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Debe ingresar una contraseña',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Contraseña"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            loading={loading}
            disabled={loading}
          >
            Iniciar sesión
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
