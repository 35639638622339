import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import CustomCalendar from '../../components/CustomCalendar';
import './DoctorSchedulePage.scss';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  List,
  Button,
  Divider,
  Alert,
  Avatar,
  Collapse,
} from 'antd';
import { getDoctorApi } from '../../api/user';
import dayjs from 'dayjs';
import NoAvatar from '../../assets/img/no-avatar.png';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getTurnPortalApi } from '../../api/portal';
import Modal from '../../components/Modal/Modal';
import NewTurnForm from '../../components/Ambulatory/NewTurnForm/NewTurnForm';
import { onPrimaryDataRead } from '../../actions';
import { generateSlots } from '../../utils/functionTurns';
import { days } from '../../utils/constants';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function DoctorSchedulePage(props) {
  const { id, type } = props.match.params;

  const [selectedDay, setSelectedDay] = useState(dayjs()); // Día seleccionado
  const [doctorData, setDoctorData] = useState([]);
  const [configSchedule, setConfigSchedule] = useState();
  const [slots, setSlots] = useState([]);
  const [turn, setTurn] = useState([]);
  const [reload, setReload] = useState(false);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { tenant, tenantTimezone, tenantId, studies } = useSelector(
    (store) => store.tenantData
  );

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      let userResponse;

      if (type === 'studie') {
        dispatch(onPrimaryDataRead(window.location.pathname.split('/')[1]));
        userResponse = {
          user: studies.find((studie) => studie.id === id),
        };
      } else {
        userResponse = await getDoctorApi({ tenantId, id });
      }
      setDoctorData(userResponse?.user);

      if (id) {
        let dataSizeSlot = userResponse?.user?.defaultSizeSlot;

        // Obtener datos de los turnos
        const turnResponse = await getTurnPortalApi(id, selectedDay, tenantId);
        setTurn(turnResponse?.turns);

        // Generar los horarios basados en el turno
        const response = await generateSlots({
          sizeSlot:
            turnResponse?.turns?.shifts.length > 0
              ? turnResponse?.turns.sizeSlot
              : dataSizeSlot,
          turns: turnResponse?.turns,
          userTurn: userResponse?.user,
          date: selectedDay,
          tenantTimezone,
        });

        setSlots(response?.filteredSlots);
        setConfigSchedule(response?.configSchedule);
      }
      setLoading(false);
    };

    getData();
    setReload(false);
  }, [id, selectedDay, reload]);

  const handleDaySelect = (day) => {
    setSelectedDay(day); // Actualiza el día seleccionado
  };

  const addTurn = (slot) => {
    setIsVisibleModal(true);
    setModalTitle(
      <p style={{ textTransform: 'capitalize' }}>
        {dayjs(selectedDay).format('dddd')} {dayjs(selectedDay).format('D')} de
        {dayjs(selectedDay).format('MMMM')}{' '}
        {dayjs(slot?.hourStart).format('HH mm')} hs
      </p>
    );
    setModalContent(
      <NewTurnForm
        date={selectedDay}
        scheduleConfig={doctorData?.scheduleConfig}
        turnId={turn?._id}
        turnUser={id}
        turnType={type}
        hourStart={slot?.hourStart}
        turns={turn?.shifts}
        doctor={doctorData}
        setIsVisibleModal={setIsVisibleModal}
        size={turn?.length > 0 ? turn.sizeSlot : doctorData?.defaultSizeSlot}
      />
    );
  };

  return (
    <div className="doctor-schedule-page">
      {/* Header */}
      <header className="header">
        <Link to={`/`}>
          <ArrowLeftOutlined className="back-arrow" />
        </Link>
        <Avatar
          alt="Foto perfil"
          src={
            doctorData?.profileImage
              ? `https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/profiles/${doctorData?.profileImage}`
              : NoAvatar
          }
          style={{
            width: '50px',
            height: '50px',
            objectFit: 'cover',
            borderRadius: '50%',
            marginRight: '16px', // Espaciado entre la foto y el texto
          }}
        />
        <h1 className="doctor-title">
          {doctorData?.name} {doctorData?.lastname}
        </h1>
      </header>

      {doctorData?.scheduleConfig?.description && (
        <Alert
          message={doctorData?.scheduleConfig?.description}
          type="info"
          style={{
            marginBottom: 16,
            backgroundColor: '#f1f1f1',
            borderColor: '#cccccc',
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
          }}
        />
      )}

      {/* Contenido Principal */}
      <Row className="content" gutter={[24, 24]}>
        {/* Calendario */}
        <Col xs={24} md={8} className="calendar-section">
          <CustomCalendar
            onDaySelect={handleDaySelect}
            tenantTimezone={tenantTimezone}
            scheduleConfig={doctorData?.scheduleConfig || []}
            configSchedule={configSchedule}
          />
        </Col>

        {/* Detalle */}
        <Col xs={24} md={16}>
          {selectedDay ? (
            <div className="details-section">
              <p className="selected-date">
                <strong>
                  {dayjs(selectedDay).format('dddd')}{' '}
                  {dayjs(selectedDay).format('D')} de{' '}
                  {dayjs(selectedDay).format('MMMM')}
                </strong>
              </p>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '12pt',
                  color: 'grey',
                }}
              >
                {slots?.length > 0
                  ? 'Seleccione un horario'
                  : 'Agenda completa'}
              </p>
              <List
                grid={{ gutter: 24, xs: 3 }}
                dataSource={slots}
                size="small"
                renderItem={(slot, index) => (
                  <List.Item>
                    <div className="turns-list__date">
                      <Button onClick={() => addTurn(slot)}>
                        <span>
                          {dayjs(slot?.hourStart)
                            .tz(tenantTimezone)
                            .format('HH:mm')}{' '}
                        </span>
                      </Button>
                    </div>
                  </List.Item>
                )}
                locale={{ emptyText: 'No hay mas turnos, intente otro día.' }}
                loading={
                  loading && {
                    style: { width: '100%' },
                    size: 'large',
                    indicator: <LoadingOutlined />,
                  }
                }
              />
            </div>
          ) : (
            <p>Seleccione un día en el calendario.</p>
          )}
        </Col>
      </Row>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="90%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}
