import React, { useEffect } from 'react';
import { onUserDataRead } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import './Home.scss';
import { Layout, Menu, message } from 'antd';
import '../../scss/primaryButton.scss';
import '../../scss/secondButton.scss';
import '../../scss/linkButton.scss';
import Spin from '../../components/Spin';
import PatientList from '../../components/Ambulatory/PatientList/PatientList';
import { useHistory } from 'react-router-dom';
import LogoEmpresa from '../../assets/img/a1-8.png';
import PdfReports from '../PdfReports';
import { getAccessTokenApi } from '../../api/auth';
const { Header, Content, Footer } = Layout;

export default function Home() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, loading } = useSelector((store) => store.userData);

  const { tenantId } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      if (!token) {
        history.push(`/`);
        return message.warning('Sesión expirada..');
      }
    };

    getData();

    dispatch(onUserDataRead());
  }, []);

  if (loading && !user?.name) {
    return <Spin />;
  }

  if (!user) return null;

  return (
    <Layout>
      {/* Header */}
      <Header
        style={{
          alignItems: 'center',
          background: '#002a52',
          padding: '0 20px',
        }}
      >
        {/* Menú de navegación */}
        <Menu
          mode="horizontal"
          style={{ backgroundColor: '#002a52', color: '#fff' }}
        >
          <Menu.Item key="1" onClick={() => history.push(`/`)}>
            Inicio
          </Menu.Item>
        </Menu>
      </Header>

      {/* Content */}
      <Content style={{ padding: '20px 50px' }}>
        {user && (
          <>
            <PatientList patient={user} />
            <PdfReports />
          </>
        )}
      </Content>

      {/* Footer */}
      <Footer
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          padding: '20px',
        }}
      >
        {/* Texto principal */}
        <div>
          © 2024 Portal de Turnos - Un desarrollo de AmpinaSoft. ¡Simplificando
          tu gestión de turnos!
        </div>

        {/* Firma con logo y enlace */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <img src={LogoEmpresa} alt="AmpinaSoft" style={{ height: '30px' }} />
          <a
            href="https://ampinasoft.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1890ff',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
          >
            Descubre nuestros servicios
          </a>
        </div>
      </Footer>
    </Layout>
  );
}
