import { basePath, apiVersion } from './config';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../utils/constants';
import jwtDecode from 'jwt-decode';
import { message, notification } from 'antd';

export function getAccessTokenApi() {
  let accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (!accessToken) {
    return null;
  }

  if (willExpireToken(accessToken)) {
    return null;
  } else {
    return accessToken;
  }
}

export function getRefreshTokenApi() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);

  if (!refreshToken || refreshToken === 'null') {
    return null;
  }

  return willExpireToken(refreshToken) ? null : refreshToken;
}

export function refreshAccessTokenApi(refreshToken) {
  const url = ` ${basePath}/${apiVersion}/refresh-access-token`;
  const bodyObj = {
    refreshToken: refreshToken,
  };

  const params = {
    method: 'POST',
    body: JSON.stringify(bodyObj),
    headers: {
      'Content-type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((response) => {
      if (response.status !== 200) {
        logout();
        window.location.reload();
        message.info('La sesion ha expirado.', 5);
      }
      return response.json();
    })
    .then((result) => {
      if (!result) {
        logout();
      } else {
        const { accessToken, refreshToken } = result;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        return accessToken;
      }
    });
}

export function logout(type) {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem('state');
  !type && message.info('La sesion ha expirado.', 5);
  window.location.reload();
}

function willExpireToken(token) {
  const seconds = 60;
  const metaToken = jwtDecode(token);
  const { exp } = metaToken;
  const now = (Date.now() + seconds) / 1000;
  return now > exp;
}
