import React, { useEffect, useState } from 'react';
import { Result, Button, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { getTenants } from '../api/tenantConfiguration';
import LogoEmpresa from '../assets/img/a1-8.png';
import { Footer } from 'antd/es/layout/layout';

export default function Error404() {
  const [tenants, setTenants] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const getData = async () => {
      const reponse = await getTenants();
      setTenants(reponse?.tenants || []);
    };

    getData();
  }, []);

  return (
    <div>
      <Result
        icon={
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            <img
              src={LogoEmpresa}
              alt="Logo"
              style={{
                width: '120px',
                height: '120px',
                borderRadius: '50%',
                objectFit: 'contain',
                backgroundColor: '#f9f9f9',
                padding: '10px',
              }}
            />
          </div>
        }
        title="Portal de Turnos"
        subTitle="Seleccione debajo con que institución o profesional desea sacar un turno"
        extra={
          <div>
            <Select
              placeholder="¿Donde quiere sacar turno?"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={(value) => {
                const newPath = `/${value}`;

                window.location.href = `${window.location.origin}${newPath}`;
              }}
              style={{ width: '75%' }}
            >
              {tenants?.map((tenant) => (
                <Select.Option value={tenant?.tenantId}>
                  {tenant?.name}
                </Select.Option>
              ))}
            </Select>
            <Footer
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
                padding: '20px',
                marginTop: '20px',
              }}
            >
              {/* Texto principal */}
              <div>
                © 2024 Portal de Turnos - Un desarrollo de AmpinaSoft.
                ¡Simplificando tu gestión de turnos!
              </div>

              {/* Firma con logo y enlace */}
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img
                  src={LogoEmpresa}
                  alt="AmpinaSoft"
                  style={{ height: '30px' }}
                />
                <a
                  href="https://ampinasoft.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#1890ff',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                  }}
                >
                  Descubre nuestros servicios
                </a>
              </div>
            </Footer>
          </div>
        }
      />
    </div>
  );
}
